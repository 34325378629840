import './style.scss';

export const ElrondLogo = () => {
	return <svg viewBox="0 0 398 99" xmlns="http://www.w3.org/2000/svg" className="main-logo flex-shrink-0 d-none d-sm-block">
	<g fill="none" fillRule="evenodd" className='logo'>
		<g  transform="translate(0 -1)" fillRule="nonzero">
			<g transform="translate(0 1)">
				<path d="m79.6 19.424c-0.4-0.4383-0.6-0.8766-0.6-1.4245 0-0.54787 0.2-0.98617 0.6-1.4245 0.8-0.76702 2-0.76702 2.8 0 0.4 0.4383 0.6 0.8766 0.6 1.4245 0 0.54787-0.2 0.98617-0.6 1.4245-0.4 0.32872-0.9 0.54787-1.4 0.54787-0.5 0.10957-1-0.10957-1.4-0.54787"></path>
				<path id="Path" d="m74.556 24.448c-0.33333-0.44138-0.55556-0.88276-0.55556-1.4345s0.22222-0.9931 0.55556-1.4345c0.77778-0.77241 2.1111-0.77241 2.8889 0 0.33333 0.44138 0.55556 0.88276 0.55556 1.4345s-0.22222 0.9931-0.55556 1.4345c-0.44444 0.33103-0.88889 0.55172-1.4444 0.55172s-1-0.22069-1.4444-0.55172"></path>
				<path id="Path" d="m69.556 30.448c-0.33333-0.33103-0.55556-0.88276-0.55556-1.4345s0.22222-0.9931 0.55556-1.4345c0.77778-0.77241 2.1111-0.77241 2.8889 0 0.33333 0.33103 0.55556 0.88276 0.55556 1.4345s-0.22222 0.9931-0.55556 1.4345c-0.44444 0.44138-0.88889 0.55172-1.4444 0.55172s-1.1111-0.22069-1.4444-0.55172"></path>
				<path id="Path" d="m64.6 35.448c-0.4-0.33103-0.6-0.88276-0.6-1.4345s0.2-0.9931 0.6-1.4345c0.8-0.77241 2-0.77241 2.8 0 0.4 0.33103 0.6 0.88276 0.6 1.4345s-0.2 1.1034-0.6 1.4345c-0.4 0.44138-0.9 0.55172-1.4 0.55172s-1-0.11034-1.4-0.55172"></path>
				<path id="Path" d="m59.556 40.424c-0.44444-0.32872-0.55556-0.8766-0.55556-1.4245 0-0.54787 0.22222-0.98617 0.55556-1.4245 0.77778-0.76702 2.1111-0.76702 2.8889 0 0.44444 0.32872 0.55556 0.8766 0.55556 1.4245 0 0.54787-0.22222 1.0957-0.55556 1.4245-0.33333 0.4383-0.88889 0.54787-1.4444 0.54787-0.55556 0.10957-1.1111-0.10957-1.4444-0.54787"></path>
				<path id="Path" d="m53 44.014c0-0.55172 0.22222-0.9931 0.55556-1.4345 0.77778-0.77241 2.1111-0.77241 2.8889 0 0.44444 0.44138 0.55556 0.88276 0.55556 1.4345s-0.22222 0.9931-0.55556 1.4345c-0.33333 0.33103-0.88889 0.55172-1.4444 0.55172s-1-0.22069-1.4444-0.55172c-0.33333-0.33103-0.55556-0.88276-0.55556-1.4345z"></path>
				<path id="Path" d="m43.556 56.424c-0.33333-0.32872-0.55556-0.8766-0.55556-1.4245 0-0.54787 0.22222-0.98617 0.55556-1.4245 0.77778-0.76702 2.1111-0.76702 2.8889 0 0.44444 0.4383 0.55556 0.8766 0.55556 1.4245 0 0.54787-0.22222 0.98617-0.55556 1.4245-0.33333 0.4383-0.88889 0.54787-1.4444 0.54787-0.55556 0.10957-1-0.10957-1.4444-0.54787"></path>
				<path id="Path" d="m38.6 61.424c-0.4-0.32872-0.6-0.8766-0.6-1.4245 0-0.54787 0.2-0.98617 0.6-1.4245 0.8-0.76702 2-0.76702 2.8 0 0.4 0.4383 0.6 0.8766 0.6 1.4245 0 0.54787-0.2 0.98617-0.6 1.4245-0.4 0.4383-0.9 0.54787-1.4 0.54787-0.5 0.10957-1-0.10957-1.4-0.54787"></path>
				<path id="Path" d="m33.556 66.448c-0.33333-0.33103-0.55556-0.88276-0.55556-1.4345s0.22222-1.1034 0.55556-1.4345c0.77778-0.77241 2.1111-0.77241 2.8889 0 0.33333 0.33103 0.55556 0.88276 0.55556 1.4345s-0.22222 0.9931-0.55556 1.4345c-0.44444 0.44138-0.88889 0.55172-1.4444 0.55172s-1-0.22069-1.4444-0.55172"></path>
				<path id="Path" d="m27.556 71.448c-0.44444-0.33103-0.55556-0.88276-0.55556-1.4345s0.22222-0.9931 0.55556-1.4345c0.77778-0.77241 2.1111-0.77241 2.8889 0 0.33333 0.44138 0.55556 0.88276 0.55556 1.4345s-0.22222 1.1034-0.55556 1.4345c-0.44444 0.44138-0.88889 0.55172-1.4444 0.55172s-1.1111-0.11034-1.4444-0.55172"></path>
				<path id="Path" d="m22.615 76.448c-0.41026-0.33103-0.61538-0.88276-0.61538-1.4345s0.20513-0.9931 0.61538-1.4345c0.82051-0.77241 2.0513-0.77241 2.7692 0 0.41026 0.44138 0.61538 0.88276 0.61538 1.4345s-0.20513 1.1034-0.61538 1.4345c-0.41026 0.33103-0.92308 0.55172-1.4359 0.55172-0.51282 0-1.0256-0.11034-1.3333-0.55172"></path>
				<path id="Path" d="m17.556 82.424c-0.44444-0.4383-0.55556-0.8766-0.55556-1.4245 0-0.54787 0.22222-0.98617 0.55556-1.4245 0.77778-0.76702 2.1111-0.76702 2.8889 0 0.44444 0.4383 0.55556 0.8766 0.55556 1.4245 0 0.54787-0.22222 0.98617-0.55556 1.4245-0.33333 0.32872-0.88889 0.54787-1.4444 0.54787-0.44444 0.10957-1-0.10957-1.4444-0.54787"></path>
				<path id="Path" d="m20.444 19.424c0.33333-0.4383 0.55556-0.8766 0.55556-1.4245 0-0.54787-0.22222-0.98617-0.55556-1.4245-0.77778-0.76702-2.1111-0.76702-2.8889 0-0.44444 0.4383-0.55556 0.8766-0.55556 1.4245 0 0.54787 0.22222 0.98617 0.55556 1.4245 0.33333 0.32872 0.88889 0.54787 1.4444 0.54787 0.55556 0.10957 1.1111-0.10957 1.4444-0.54787"></path>
				<path id="Path" d="m25.4 24.448c0.4-0.44138 0.6-0.88276 0.6-1.4345s-0.2-0.9931-0.6-1.4345c-0.8-0.77241-2-0.77241-2.8 0-0.4 0.44138-0.6 0.88276-0.6 1.4345s0.2 0.9931 0.6 1.4345c0.4 0.33103 0.9 0.55172 1.4 0.55172s1-0.22069 1.4-0.55172"></path>
				<path id="Path" d="m30.444 30.448c0.33333-0.33103 0.55556-0.88276 0.55556-1.4345s-0.22222-0.9931-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.33333 0.33103-0.55556 0.88276-0.55556 1.4345s0.22222 0.9931 0.55556 1.4345c0.44444 0.44138 0.88889 0.55172 1.4444 0.55172 0.44444 0 1-0.22069 1.4444-0.55172"></path>
				<path id="Path" d="m36.444 35.448c0.33333-0.33103 0.55556-0.88276 0.55556-1.4345s-0.22222-0.9931-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.33333 0.33103-0.55556 0.88276-0.55556 1.4345s0.22222 1.1034 0.55556 1.4345c0.44444 0.44138 0.88889 0.55172 1.4444 0.55172 0.44444 0 1-0.11034 1.4444-0.55172"></path>
				<path id="Path" d="m41.385 40.424c0.41026-0.32872 0.61538-0.8766 0.61538-1.4245 0-0.54787-0.20513-0.98617-0.61538-1.4245-0.71795-0.76702-2.0513-0.76702-2.7692 0-0.41026 0.32872-0.61538 0.8766-0.61538 1.4245 0 0.54787 0.20513 1.0957 0.61538 1.4245 0.41026 0.4383 0.92308 0.54787 1.4359 0.54787 0.51282 0.10957 1.0256-0.10957 1.3333-0.54787"></path>
				<path id="Path" d="m47 44.014c0-0.55172-0.22222-0.9931-0.55556-1.4345-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.44444 0.44138-0.55556 0.88276-0.55556 1.4345s0.22222 0.9931 0.55556 1.4345c0.33333 0.33103 0.88889 0.55172 1.4444 0.55172s1-0.22069 1.4444-0.55172c0.44444-0.33103 0.55556-0.88276 0.55556-1.4345z"></path>
				<path id="Path" d="m51.444 51.448c0.44444-0.44138 0.55556-0.88276 0.55556-1.4345s-0.22222-0.9931-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.44444 0.44138-0.55556 0.88276-0.55556 1.4345s0.22222 0.9931 0.55556 1.4345c0.33333 0.33103 0.88889 0.55172 1.4444 0.55172s1.1111-0.22069 1.4444-0.55172"></path>
				<path id="Path" d="m56.385 56.424c0.41026-0.32872 0.61538-0.8766 0.61538-1.4245 0-0.54787-0.20513-0.98617-0.61538-1.4245-0.82051-0.76702-2.0513-0.76702-2.7692 0-0.41026 0.4383-0.61538 0.8766-0.61538 1.4245 0 0.54787 0.20513 0.98617 0.61538 1.4245 0.41026 0.4383 0.92308 0.54787 1.4359 0.54787 0.41026 0.10957 0.92308-0.10957 1.3333-0.54787"></path>
				<path id="Path" d="m62.444 61.424c0.33333-0.32872 0.55556-0.8766 0.55556-1.4245 0-0.54787-0.22222-0.98617-0.55556-1.4245-0.77778-0.76702-2.1111-0.76702-2.8889 0-0.33333 0.4383-0.55556 0.8766-0.55556 1.4245 0 0.54787 0.22222 0.98617 0.55556 1.4245 0.44444 0.4383 0.88889 0.54787 1.4444 0.54787 0.44444 0.10957 1-0.10957 1.4444-0.54787"></path>
				<path id="Path" d="m67.444 66.448c0.33333-0.33103 0.55556-0.88276 0.55556-1.4345s-0.22222-1.1034-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.33333 0.33103-0.55556 0.88276-0.55556 1.4345s0.22222 0.9931 0.55556 1.4345c0.44444 0.44138 0.88889 0.55172 1.4444 0.55172s1-0.22069 1.4444-0.55172"></path>
				<path id="Path" d="m72.444 71.448c0.44444-0.33103 0.55556-0.88276 0.55556-1.4345s-0.22222-0.9931-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.33333 0.44138-0.55556 0.88276-0.55556 1.4345s0.22222 1.1034 0.55556 1.4345c0.44444 0.44138 0.88889 0.55172 1.4444 0.55172s1.1111-0.11034 1.4444-0.55172"></path>
				<path id="a" d="m77.444 76.448c0.44444-0.33103 0.55556-0.88276 0.55556-1.4345s-0.22222-0.9931-0.55556-1.4345c-0.77778-0.77241-2.1111-0.77241-2.8889 0-0.44444 0.44138-0.55556 0.88276-0.55556 1.4345s0.22222 1.1034 0.55556 1.4345c0.33333 0.33103 0.88889 0.55172 1.4444 0.55172s1.1111-0.11034 1.4444-0.55172"></path>
				<path d="m82.385 82.424c0.41026-0.4383 0.61538-0.8766 0.61538-1.4245 0-0.54787-0.20513-0.98617-0.61538-1.4245-0.82051-0.76702-2.0513-0.76702-2.7692 0-0.41026 0.4383-0.61538 0.8766-0.61538 1.4245 0 0.54787 0.20513 0.98617 0.61538 1.4245 0.41026 0.32872 0.92308 0.54787 1.4359 0.54787 0.41026 0.10957 0.92308-0.10957 1.3333-0.54787"></path>
				<path d="m33.036 21c5.2647-3.4327 11.036-5.0481 17.414-5.149 6.2771 0 12.048 1.7163 17.414 5.149l11.137-11.106c-8.7069-6.5625-18.123-9.8942-28.551-9.8942-10.428 0-19.945 3.3317-28.449 9.8942l11.036 11.106z"></path>
				<path d="m21 66.742c-3.5854-5.3754-5.3268-11.157-5.3268-17.343 0-6.2883 1.7415-12.069 5.3268-17.343l-11.166-11.055c-6.5561 8.5196-9.8341 17.952-9.8341 28.5 0 10.345 3.278 19.879 9.9366 28.5l11.063-11.258z"></path>
				<path d="m67.964 78c-5.2647 3.5333-11.036 5.3504-17.313 5.4513-6.3783 0-12.25-1.7162-17.515-5.3504l-11.137 11.004c8.6057 6.6628 18.123 9.9941 28.652 9.8932 10.428-0.10095 19.844-3.4323 28.348-9.8932l-11.036-11.105z"></path>
				<path d="m79 32.258c3.5333 5.3754 5.3504 11.258 5.2494 17.648-0.10095 6.1868-1.8171 11.867-5.2494 17.039l11.105 11.055c6.5618-8.5196 9.8932-17.952 9.8932-28.297 0.10095-10.548-3.2304-20.082-9.8932-28.703l-11.105 11.258z"></path>
				<path d="m90 81c4.3636 0 8 3.5325 8 8 0 4.3636-3.5325 8-8 8-4.3636 0-8-3.5325-8-8s3.5325-8 8-8"></path>
				<path d="m10 81c4.4211 0 8 3.5325 8 8 0 4.3636-3.5789 8-8 8-4.4211 0-8-3.5325-8-8s3.5789-8 8-8"></path>
				<path d="m90 1c4.4211 0 8 3.5325 8 8 0 4.4675-3.5789 8-8 8s-8-3.5325-8-8c0-4.4675 3.5789-8 8-8"></path>
				<path d="m10 1c4.3636 0 8 3.5789 8 8 0 4.4211-3.5325 8-8 8-4.3636 0-8-3.5789-8-8 0-4.4211 3.5325-8 8-8"></path>
				<path d="m168.3 68.878-5.0316-4.5269c-3.0455 3.5949-9.1364 5.7252-14.3 5.7252-8.6067 0-15.492-5.1926-16.287-13.581h38.134c1.7213-17.975-8.7391-26.496-22.51-26.496-13.506 0-23.304 9.7195-23.304 23.433 0 14.38 9.666 23.567 23.702 23.567 7.1502-0.13314 14.962-2.796 19.597-8.1218zm-4.2985-18.878h-31c1.3191-8.3663 8.0468-13 15.83-13 8.7064 0 14.643 4.505 15.17 12.743v0.25743zm26-38h-7v64h7v-64zm20.933 18.809h-6.9333v45.191h7.4667v-25.195c0-8.265 6-13.464 13.333-13.597 3.0667 0 5.4667 0.79984 7.8667 2.5328l3.3333-6.3987c-2.8-2.1329-6.5333-3.466-10.533-3.3327-5.2 0-10.8 1.5997-13.867 7.4652l-0.66667-6.6653zm29.067 22.891c0 13.714 9.8807 23.3 23.5 23.3s23.5-9.5864 23.5-23.3c0-13.98-9.7472-23.7-23.5-23.7-13.486 0-23.5 9.7195-23.5 23.7zm40 0.066412c-0.13333 9.4476-6.8 16.234-16.133 16.234-9.2 0-15.867-6.7863-15.867-16.234 0-10.113 6.8-16.766 16-16.766 9.0667 0 16 6.7863 16 16.766zm18-23.231v45.465h7.5486v-24.337c0-8.157 5.7962-14.709 14.019-14.843 8.7618 0 13.884 5.4826 13.884 14.174v25.006h7.5486v-25.006c0-13.372-8.4922-20.994-20.085-20.994-6.0658 0-11.727 2.5407-15.636 7.8895l-0.40439-7.2209-6.8746-0.13372zm99.868-18.535h-7.4141v27.094c-3.1775-6.1396-10.459-9.076-16.814-9.076-12.71 0-22.639 8.2752-22.639 23.491 0 14.949 9.9296 23.491 22.772 23.491 6.6197 0 13.239-2.9363 16.814-9.2094l0.39718 8.2752h7.0169l-0.13239-64.066zm-23.935 25c8.9707 0 16.067 6.7073 16.067 16.366 0 9.9268-7.0962 16.634-16.067 16.634-8.8368 0-15.933-6.0366-15.933-16.5 0-10.598 6.9623-16.366 15.665-16.5h0.26778z"></path>
			</g>
		</g>
	</g>
</svg>
}

export const SimpleElrondLogo = ({style}) => {
	return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 127.7 128" style={style}>
	<path d="M102.3,25.4c-0.5-0.5-0.8-1-0.8-1.7c0-0.7,0.3-1.2,0.8-1.7c1-0.9,2.6-0.9,3.7,0c0.5,0.5,0.8,1,0.8,1.7  c0,0.7-0.3,1.2-0.8,1.7c-0.5,0.4-1.2,0.7-1.8,0.7C103.5,26.2,102.8,26,102.3,25.4z"/>
	<path d="M95.9,32.3c-0.4-0.5-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.4,0.5,0.7,1,0.7,1.7  c0,0.7-0.3,1.2-0.7,1.7c-0.5,0.4-1,0.7-1.7,0.7S96.4,32.7,95.9,32.3z"/>
	<path d="M88.9,38.9c-0.4-0.4-0.7-1-0.7-1.7s0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.4,0.4,0.7,1,0.7,1.7  s-0.3,1.2-0.7,1.7c-0.5,0.5-1,0.7-1.7,0.7S89.3,39.3,88.9,38.9z"/>
	<path d="M82.2,45.6c-0.5-0.4-0.8-1-0.8-1.7c0-0.7,0.3-1.2,0.8-1.7c1-0.9,2.6-0.9,3.7,0c0.5,0.4,0.8,1,0.8,1.7  c0,0.7-0.3,1.3-0.8,1.7c-0.5,0.5-1.2,0.7-1.8,0.7C83.4,46.3,82.8,46.2,82.2,45.6z"/>
	<path d="M75.8,52.3c-0.5-0.4-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.5,0.4,0.7,1,0.7,1.7  c0,0.7-0.3,1.3-0.7,1.7c-0.4,0.5-1,0.7-1.7,0.7C76.9,53.1,76.2,52.9,75.8,52.3z"/>
	<path d="M68.1,57.3c0-0.7,0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.5,0.5,0.7,1,0.7,1.7c0,0.7-0.3,1.2-0.7,1.7  c-0.4,0.4-1,0.7-1.7,0.7s-1.2-0.3-1.7-0.7C68.3,58.6,68.1,58,68.1,57.3z"/>
	<path d="M55.6,72.1c-0.4-0.4-0.7-1-0.7-1.7s0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.5,0.5,0.7,1,0.7,1.7  s-0.3,1.2-0.7,1.7c-0.4,0.5-1,0.7-1.7,0.7C56.7,72.9,56.1,72.7,55.6,72.1z"/>
	<path d="M48.7,78.8c-0.5-0.4-0.8-1-0.8-1.7c0-0.7,0.3-1.2,0.8-1.7c1-0.9,2.6-0.9,3.7,0c0.5,0.5,0.8,1,0.8,1.7  c0,0.7-0.3,1.2-0.8,1.7c-0.5,0.5-1.2,0.7-1.8,0.7C49.8,79.6,49.2,79.3,48.7,78.8z"/>
	<path d="M42.2,85.6c-0.4-0.4-0.7-1-0.7-1.7s0.3-1.3,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.4,0.4,0.7,1,0.7,1.7  s-0.3,1.2-0.7,1.7c-0.5,0.5-1,0.7-1.7,0.7S42.8,86,42.2,85.6z"/>
	<path d="M35.5,92.3c-0.5-0.4-0.7-1-0.7-1.7s0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.4,0.5,0.7,1,0.7,1.7  s-0.3,1.3-0.7,1.7c-0.5,0.5-1,0.7-1.7,0.7C36.6,93,35.9,92.8,35.5,92.3z"/>
	<path d="M28.6,99c-0.5-0.4-0.8-1-0.8-1.7c0-0.7,0.3-1.2,0.8-1.7c1-0.9,2.6-0.9,3.5,0c0.5,0.5,0.8,1,0.8,1.7  c0,0.7-0.3,1.3-0.8,1.7c-0.5,0.4-1.2,0.7-1.8,0.7C29.6,99.7,29,99.5,28.6,99z"/>
	<path d="M22,105.7c-0.5-0.5-0.7-1-0.7-1.7s0.3-1.2,0.7-1.7c0.9-0.9,2.5-0.9,3.4,0c0.5,0.5,0.7,1,0.7,1.7  s-0.3,1.2-0.7,1.7c-0.4,0.4-1,0.7-1.7,0.7C23.2,106.5,22.6,106.2,22,105.7z"/>
	<path d="M25.4,25.4c0.4-0.5,0.7-1,0.7-1.7c0-0.7-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.5,0.5-0.7,1-0.7,1.7  c0,0.7,0.3,1.2,0.7,1.7c0.4,0.4,1,0.7,1.7,0.7C24.4,26.2,25,26,25.4,25.4z"/>
	<path d="M32.1,32.3c0.5-0.5,0.8-1,0.8-1.7c0-0.7-0.3-1.2-0.8-1.7c-1-0.9-2.6-0.9-3.7,0c-0.5,0.5-0.8,1-0.8,1.7  c0,0.7,0.3,1.2,0.8,1.7c0.5,0.4,1.2,0.7,1.8,0.7C30.9,32.9,31.6,32.7,32.1,32.3z"/>
	<path d="M38.9,38.9c0.4-0.4,0.7-1,0.7-1.7s-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.4,0.4-0.7,1-0.7,1.7  s0.3,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7C37.8,39.6,38.4,39.3,38.9,38.9z"/>
	<path d="M45.6,45.6c0.4-0.4,0.7-1,0.7-1.7c0-0.7-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.4,0.4-0.7,1-0.7,1.7  c0,0.7,0.3,1.3,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7C44.5,46.3,45.1,46.2,45.6,45.6z"/>
	<path d="M52.2,52.3c0.5-0.4,0.8-1,0.8-1.7c0-0.7-0.3-1.2-0.8-1.7c-0.9-0.9-2.6-0.9-3.5,0c-0.5,0.4-0.8,1-0.8,1.7  c0,0.7,0.3,1.3,0.8,1.7c0.5,0.5,1.2,0.7,1.8,0.7C51.1,53.1,51.8,52.9,52.2,52.3z"/>
	<path d="M59.7,57.3c0-0.7-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.5,0.5-0.7,1-0.7,1.7c0,0.7,0.3,1.2,0.7,1.7  c0.4,0.4,1,0.7,1.7,0.7c0.7,0,1.2-0.3,1.7-0.7C59.5,58.6,59.7,58,59.7,57.3z"/>
	<path d="M65.7,65.8c0.5-0.5,0.7-1,0.7-1.7s-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.5,0.5-0.7,1-0.7,1.7  s0.3,1.2,0.7,1.7c0.4,0.4,1,0.7,1.7,0.7C64.7,66.5,65.3,66.2,65.7,65.8z"/>
	<path d="M72.4,72.1c0.5-0.4,0.8-1,0.8-1.7s-0.3-1.2-0.8-1.7c-1-0.9-2.6-0.9-3.5,0c-0.5,0.5-0.8,1-0.8,1.7  s0.3,1.2,0.8,1.7c0.5,0.5,1.2,0.7,1.8,0.7C71.2,72.9,71.9,72.7,72.4,72.1z"/>
	<path d="M79.2,78.8c0.4-0.4,0.7-1,0.7-1.7c0-0.7-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.4,0.5-0.7,1-0.7,1.7  c0,0.7,0.3,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7C78,79.6,78.7,79.3,79.2,78.8z"/>
	<path d="M85.9,85.6c0.4-0.4,0.7-1,0.7-1.7s-0.3-1.3-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.4,0.4-0.7,1-0.7,1.7  s0.3,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7C84.9,86.3,85.4,86,85.9,85.6z"/>
	<path d="M92.2,92.3c0.5-0.4,0.7-1,0.7-1.7s-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.4,0.5-0.7,1-0.7,1.7  s0.3,1.3,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7C91.1,93,91.8,92.8,92.2,92.3z"/>
	<path d="M99.3,99c0.5-0.4,0.7-1,0.7-1.7c0-0.7-0.3-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.4,0c-0.5,0.5-0.7,1-0.7,1.7  c0,0.7,0.3,1.3,0.7,1.7c0.4,0.4,1,0.7,1.7,0.7S98.9,99.5,99.3,99z"/>
	<path d="M106,105.7c0.5-0.5,0.8-1,0.8-1.7s-0.3-1.2-0.8-1.7c-1-0.9-2.6-0.9-3.5,0c-0.5,0.5-0.8,1-0.8,1.7  s0.3,1.2,0.8,1.7c0.5,0.4,1.2,0.7,1.8,0.7C104.8,106.5,105.4,106.2,106,105.7z"/>
	<path d="M41.3,27.3c6.8-4.5,14.3-6.6,22.6-6.7c8.1,0,15.6,2.2,22.6,6.7l14.4-14.4C89.6,4.3,77.4,0,63.9,0  C50.4,0,38,4.3,27,12.9L41.3,27.3z"/>
	<path d="M26.9,86.4c-4.6-7-6.8-14.4-6.8-22.4c0-8.1,2.2-15.6,6.8-22.4L12.6,27.3C4.2,38.3,0,50.5,0,64.1  C0,77.5,4.2,89.8,12.7,101L26.9,86.4z"/>
	<path d="M86.4,100.7c-6.8,4.6-14.3,7-22.4,7.1c-8.3,0-15.9-2.2-22.7-7l-14.4,14.3c11.1,8.7,23.5,13,37.1,12.9  c13.5-0.1,25.7-4.5,36.7-12.9L86.4,100.7z"/>
	<path d="M100.5,41.4c4.6,7,7,14.6,6.8,22.8c-0.1,8-2.4,15.3-6.8,22l14.4,14.3c8.5-11,12.9-23.2,12.9-36.6  c0.1-13.6-4.2-26-12.9-37.1L100.5,41.4z"/>
	<path d="M115.5,105.2c5.5,0,10.1,4.5,10.1,10.1c0,5.5-4.5,10.1-10.1,10.1c-5.5,0-10.1-4.5-10.1-10.1  S109.9,105.2,115.5,105.2z"/>
	<path d="M12.5,105.2c5.5,0,10,4.5,10,10.1c0,5.5-4.5,10.1-10,10.1c-5.5,0-10-4.5-10-10.1S7,105.2,12.5,105.2z"/>
	<path d="M115.7,2.2c5.5,0,10,4.5,10,10.1c0,5.6-4.5,10.1-10,10.1c-5.5,0-10-4.5-10-10.1C105.7,6.7,110.2,2.2,115.7,2.2z  "/>
	<path d="M12.3,2.2c5.5,0,10.1,4.5,10.1,10c0,5.5-4.5,10-10.1,10c-5.5,0-10.1-4.5-10.1-10C2.2,6.7,6.7,2.2,12.3,2.2z"/>
</svg>
}
